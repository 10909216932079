@import-normalize; /* bring in normalize.css styles */

:root {
  --accent-color: #00A3FF;
  --main-font: 'Poppins', sans-serif;
  --main-font-color: #494949;
  --heading-color: #77B255;
  --heading-font: 'Oswald', sans-serif;
  --body-color: #D1EFFF;
  --sand-bottom: linear-gradient(to bottom, transparent 80%,#E3DAC9 80%);
}

* {
  box-sizing: border-box;
  outline-color: var(--accent-color);
}

html {
  font-size: 16px;
}

body {
  background-color: var(--body-color);
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New', monospace;
}

.screen-reader-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}