.TodoDashboardPage {
    display: flex;
    flex: 1;
}

.TodoDashboardPage__arrow-container {
    align-items: center;
    background: var(--sand-bottom);
    border-right: solid 1px white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.TodoDashboardPage__arrow-container--right {
    border: none;
    border-left: solid 1px white;
}