.Button {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    height: 3.5rem;
    width: 3.5rem;
}

.Button--delete {
    /* background: url(../svgs/delete.svg) no-repeat center; */
}

.Button--left {
    /* background-image: url(../svgs/arrow_back.svg); */
}

.Button--migrate {
    /* background: url(../svgs/migrate.svg) no-repeat center; */
}

.Button--right {
    /* background-image: url(../svgs/arrow_forward.svg); */
}

.Button--today {
    background-image: url(../svgs/today.svg);
}

.Button--login {
    background-color: var(--accent-color);
    border: none;
    border-bottom: solid 5px transparent;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    font-family: var(--main-font);
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.75rem;
}

.Button--login:hover, 
.Button--login:focus {
    border-bottom-color: #0072b3;
}

.Button--logout {
    color: #5A5A5A;
    font-family: var(--main--font);
    font-size: 1rem;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
}

.Button--logout:hover {
    text-decoration: underline;
}