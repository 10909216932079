.TodoItem {
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    padding: 0.5rem;
    min-height: 3.5rem;
}

.TodoItem:hover, .TodoItem:active {
    background-color: #C2EAFF;
}

.TodoItem__button-group {
    display: flex;
    justify-content: flex-end;
}

.TodoItem__task-wrapper {
    flex: 1;
}

.TodoItem__task {
    display: block;
    outline-color: var(--accent-color);
    outline-offset: 5px;
}

.TodoItem__edit-text-input {
    display: block;
    font-family: var(--main-font);
    font-size: 1rem;
    height: 56px;
}

.TodoItem .Button--delete,
.TodoItem .Button--migrate{
    opacity: 0;
}

.TodoItem:hover .Button--delete, 
.TodoItem:hover .Button--migrate,
.Button--delete:focus,
.Button--migrate:focus {
    opacity: 1;
}

.TodoItem__options-group {
    display: none;
}

/* Touch devices */
@media (pointer: coarse) {
    .TodoItem .Button--delete,
    .TodoItem .Button--migrate {
        opacity: 1;
    }

    /* Hide hover over icons */
    .TodoItem:hover .TodoItem__button-group,
    .TodoItem .TodoItem__button-group{
        display: none;
        opacity: 0;
    }

    .TodoItem__options-group {
        align-items: center;
        position: absolute;
        display: flex;
        left: 100%;
        top: 0px;
        height: 100%;
        width: 100%;
        background: #00A3FF;
    }
}

.TodoItem__checkbox {
    display: flex;
    flex: 1;
}

.TodoItem__label {
    align-items: center;
    color: var(--main-font-color);
    cursor: pointer;
    display: flex;
    padding: 0 1rem 0 35px;
}

.TodoItem__custom-checkbox {
    background-color: white;
    border: 2px solid var(--accent-color);
    display: block;
    height: 20px;
    margin-right: 0.8rem;
    width: 20px;
}

.TodoItem__custom-checkbox:hover {
    background-color: transparent;
    background-image: url(../svgs/done-alt.svg);
    background-size: 90%;
}

.TodoItem__real-checkbox:checked + .TodoItem__label .TodoItem__custom-checkbox {
    background-color: var(--accent-color);
    background-image: url(../svgs/done.svg);
    background-size: 90%;
    border-radius: 3px;
}

.TodoItem__real-checkbox:not(:checked) + .TodoItem__label .TodoItem__custom-checkbox {
    background-color: white;
    background-image: none;
}

.TodoItem__real-checkbox:focus + .TodoItem__label .TodoItem__custom-checkbox {
    outline: solid 2px var(--accent-color);
    outline-offset: 3px;
}

