.TodoForm {
    align-items: center;
    display: flex;
    flex: 1;
    margin-bottom: 1rem;
    padding: 0 1rem;
}

@media (min-width: 900px) {
    .TodoForm {
        padding: 1rem;
    }
}

.TodoForm__button {
    background-color: var(--accent-color);
    border: none;
    border-radius: 2.5rem;
    color: white;
    height: 2.75rem;
    text-transform: uppercase;
    width: 2.75rem;
}

.TodoForm__input-text {
    border: solid 2px var(--accent-color);
    border-radius: 1.5rem;
    font-family: var(--main-font);
    font-size: 1.25rem;
    line-height: 1;
    margin-right: 1.25rem;
    outline: none;
    padding: 0.5rem 1.5rem;
    width: calc(100% - 3.5rem);
}

.TodoForm__input-text:focus {
    box-shadow: 0 0 0 4px var(--accent-color);
}