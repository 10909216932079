.LandingPage {
    color: var(--main-font-color);
    display: flex;
    flex-direction: column;
}

.LandingPage a {
    color: var(--accent-color);
    text-decoration: none;
}

.LandingPage a:hover {
    text-decoration: underline;
}

.LandingPage__cta {
    align-items: center;
    background: linear-gradient(to bottom, transparent 70%,#E3DAC9 70% 90%, white 90%); 
    display: flex;
    flex-direction: column;
}

.LandingPage__cta-title {
    font-size: 2.25rem;
    margin: 3rem 0;
    text-align: center;
    width: 60%;
}

.LandingPage__splash-image {
    filter: drop-shadow(0 1px 3px rgba(0,0,0,0.12)) drop-shadow(0 1px 2px rgba(0,0,0,0.24));
    margin: 5.5rem auto 0 auto;
    width: 100%;
}

.LandingPage__content-container {
    margin: 0 auto;
    width: 80%;
}

.LandingPage__features {
    background-color: white;
    padding: 0 0 2rem 0;
}

.LandingPage__features-title {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.LandingPage_features-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.LandingPage_feature-item {
    flex: 1;
    padding: 0 2rem;
    text-align: center;
}

.LandingPage_feature-item:first-child {
    padding: 0 2rem 0 0;
}

.LandingPage_feature-item:last-child {
    padding: 0 0 0 2rem;
}

.LandingPage_feature-icon {
    display: block;
    margin: 0 auto 1rem auto;
    width: 4rem;
}

.LandingPage_feature-title,
.LandingPage_team-title {
    font-size: 2.25rem;
    margin: 0 0 2.25rem 0;
}

.LandingPage_feature-description,
.LandingPage_team-description {
    font-size: 1.125rem;
    font-weight: 300;
}

.LandingPage_team {
    background-color: #FAFAFA;
    padding: 2.25rem 0;
}

.LandingPage_team-text {
    padding-right: 5rem;
}

.LandingPage_team-content {
    align-items: center;
    display: flex;
}

.LandingPage__footer {
    background-color: white;
    padding: 4rem 0;
    text-align: center;
}

.LandingPage__footer-icon {
    padding: 0.25rem 1rem;
    width: 4rem;
}

.LandingPage__footer-text {
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

@media(max-width: 930px) {
    .LandingPage__cta {
        background: linear-gradient(to bottom, transparent 90%, white 90%); 
    }
    .LandingPage__cta-title {
        font-size: 1.75rem;
        width: 80%;
    }
    .LandingPage__splash-image {
        margin: 3rem auto 0 auto;
        object-fit: cover;
        object-position: 0 0;
    }
    .LandingPage_features-list {
        flex-direction: column;
    }

    .LandingPage__features-title {
        font-size: 2rem;
    }

    .LandingPage_feature-icon {
        margin: 1.25rem auto 0.25rem auto;
    }

    .LandingPage_feature-title,
    .LandingPage_team-title {
        font-size: 1.5rem;
        margin: 0;
    }

    .LandingPage_feature-item:first-child, 
    .LandingPage_feature-item:last-child {
        padding: 0;
    }

    .LandingPage_team {
        text-align: center;
    }

    .LandingPage_team-text {
        padding: 0;
    }

    .LandingPage_team img {
        display: none;
    }
}