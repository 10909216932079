.Header {
    margin: 0;
    padding: 1.25rem 2rem;
    display: flex;
    justify-content: space-between;
}

.Header__brand {
    display: flex;
}

.Header__logo {
    padding-right: 0.1rem;
    width: 1.95rem;
}

.Header__title {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 1.75rem;
    font-weight: 400;
    text-transform: uppercase;
}

.Header__button-group {
    display: flex;
    align-items: center;
}

.Header__button {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    height: 3.5rem;
    width: 3.5rem;
}

.Header__button--info {
    background-image: url(../svgs/info.svg);
    
}

.Header__button--close {
    background-image: url(../svgs/close.svg);
}