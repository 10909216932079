.TodoByDate-container {
    background: var(--sand-bottom);  
    display: flex;
    flex: 1; /* to stretch to bottom */
}

.TodoByDate {
    border-right: 1px solid white;
    flex: 1;
    position: relative;
}

.TodoByDate:last-child {
    border-right: none;
}

.TodoByDate__header {
    background-color: #E8F7FF;
    display: flex;
    justify-content: space-between;
}

@media (min-width: 900px) {
    .TodoByDate__header {
        background-color: transparent;
        justify-content: center;
    }
}


.TodoByDate__date {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 2rem;
    font-weight: 400;
    margin: 0 0 1.25rem 0;
    text-align: center;
    text-transform: uppercase;
}