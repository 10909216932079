.TodoList {
    height: 60%;
    overflow-y: auto;
    padding: 1rem 0;
}

@media (min-width: 900px) {
    .TodoList {
        overflow-y: auto;
        padding: 0 2rem;
        width: 100%;
    }
}