.FourOhFour {
    align-items: center;
    background: url(../svgs/cactus.svg) no-repeat bottom 15% left 5%/16%,
                url(../svgs/hippo.svg) no-repeat bottom 10% left 60%/40%,
                var(--sand-bottom);
    color: var(--main-font-color);
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
}

@media(min-width: 900px) {
    .FourOhFour {
        background: url(../svgs/cactus.svg) no-repeat bottom 15% left 35%/8%,
                url(../svgs/hippo.svg) no-repeat bottom 10% left 60%/25%,
                var(--sand-bottom);
    }
}