.HeaderMobile {
    background-color: #E8F7FF;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.HeaderMobile__brand {
    display: flex;
    padding: 0 0 0 1rem;
}